<script>
import { ref, computed, onMounted } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatNumber } from '/~/utils/format/numeric'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useActivity } from '/~/composables/activity'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

export default {
  name: 'fly-points-transfer-successful',
  components: {
    BaseIcon,
    BaseButton,
    BaseLoader,
    BaseImage,
  },
  props: {
    number: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { providerTitle } = useProvider()
    const { calculatePoints, findPartner } = usePointsPrograms()
    const { getPointsOrderItem } = useActivity()
    const { formatBusinessNumber } = useLocalization()

    const transfer = ref(null)
    const pointsSpent = ref(null)
    const pointsReceived = ref(null)
    const selectedPartner = ref({})

    onMounted(async () => {
      transfer.value = await getPointsOrderItem(props.number)
      pointsSpent.value = transfer.value.paymentMethods[0].points
      const { data } = await calculatePoints({
        type: transfer.value.member.type,
        amount: transfer.value.paymentMethods[0].amount,
      })

      pointsReceived.value = data.programPoints
      selectedPartner.value = findPartner(transfer.value.member.type)
    })

    const statusCompleted = computed(() =>
      ['completed'].includes(transfer.value?.status)
    )
    const statusPending = computed(() =>
      ['pending', 'processing'].includes(transfer.value?.status)
    )
    const statusFailed = computed(
      () => !statusCompleted.value && !statusPending.value
    )

    const title = computed(() => {
      let statusLabel

      if (statusCompleted.value) {
        statusLabel = 'is successful'
      } else if (statusPending.value) {
        statusLabel = 'is processing'
      } else {
        statusLabel = 'failed'
      }

      return `Transfer ${statusLabel}`
    })

    const icon = computed(() => {
      if (statusCompleted.value) {
        return 'heroicons/solid/check-circle'
      } else if (statusPending.value) {
        return 'heroicons/solid/clock'
      }
      return 'heroicons/solid/x-circle'
    })

    const iconColor = computed(() => {
      if (statusCompleted.value) {
        return 'text-fg-success'
      } else if (statusPending.value) {
        return 'text-fg-warning'
      }
      return 'text-fg-error'
    })

    return {
      formatBusinessNumber,
      formatNumber,
      providerTitle,
      transfer,
      selectedPartner,
      title,
      icon,
      iconColor,
      pointsSpent,
      pointsReceived,
      statusFailed,
    }
  },
  computed: {
    formattedDate() {
      return formatDate('daymonthyearlongtime', this.transfer.completedAt)
    },
    partner() {
      const { member: partner } = this.transfer

      if (!partner) return { abn: '' }

      return {
        ...partner,
        type: partner.type.toUpperCase(),
      }
    },
    partnerIcon() {
      return this.selectedPartner.listIcon || this.selectedPartner.icon
    },
  },
  destroyed() {
    this.transfer = null
  },
  methods: {
    onLeave() {
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<template>
  <div class="flex min-h-full pr-6">
    <div
      class="min-h-full w-full grow rounded-b-3xl rounded-t-3xl bg-eonx-neutral-50 sm:rounded-b-none sm:pt-11"
    >
      <div
        v-if="transfer"
        class="mx-auto flex h-full min-h-full w-full flex-col bg-white px-10 py-[30px] sm:w-sm sm:rounded-t-3xl"
      >
        <div class="text-eonx-neutral-800">
          <div class="flex flex-col items-center">
            <div class="h-20 w-20" :class="iconColor">
              <base-icon :svg="icon" />
            </div>

            <span class="mt-6 text-2xl font-bold leading-7">
              {{ title }}
            </span>
          </div>
          <div class="mt-[30px] text-center font-bold">
            Transaction No. #{{ transfer.number }}
          </div>
          <div class="mt-[5px] text-center font-bold">
            {{ formattedDate }}
          </div>
          <div
            v-if="!statusFailed"
            class="mt-5 text-center leading-6 text-eonx-neutral-600"
          >
            {{ selectedPartner.programCurrency }} will normally take 24 hours to
            appear in your {{ selectedPartner.name }} account but may take up to
            14 days.
          </div>
        </div>

        <h3 class="mt-11 text-base font-bold text-eonx-neutral-800">
          Member details
        </h3>
        <div class="my-6 flex h-12 items-center space-x-4">
          <div class="flex w-12 items-center justify-center">
            <base-image :src="partnerIcon" :ratio="false" />
          </div>
          <div class="ml-4 flex flex-col justify-between">
            <span class="font-bold text-eonx-neutral-800">
              {{ partner.name }}
            </span>
            <span class="text-sm text-eonx-neutral-600">
              {{ selectedPartner.accountNumberLabel }}
              {{ formatBusinessNumber(partner.accountNumber) }}
            </span>
          </div>
        </div>

        <div class="border-t py-6">
          <h3 class="text-base font-bold text-eonx-neutral-800">
            Transfer amounts
          </h3>
          <div class="mt-5 flex items-center justify-between">
            <div class="w-12 p-1">
              <base-icon class="w-full text-primary" svg="points/token" />
            </div>
            <div class="ml-4 grow font-bold text-eonx-neutral-800">
              {{ providerTitle }} Points
            </div>
            <div class="text-sm text-eonx-neutral-600">
              -{{ formatNumber(pointsSpent) }}
            </div>
          </div>
          <div class="mt-5 flex items-center justify-between">
            <div class="flex w-12 items-center justify-center p-1">
              <base-image
                :src="partnerIcon"
                class="h-full w-full"
                :ratio="false"
              />
            </div>
            <div class="ml-4 grow font-bold text-eonx-neutral-800">
              {{ selectedPartner.programCurrency }}
            </div>
            <div class="text-sm text-eonx-neutral-600">
              +{{ formatNumber(pointsReceived) }}
            </div>
          </div>
        </div>
        <div class="mt-11 border-t bg-white py-5">
          <div class="flex w-full space-x-6">
            <base-button
              data-testid="btn-make-transfer"
              class="flex-1"
              look="outlined-color"
              @click="$router.push({ name: 'fly' })"
            >
              Make another transfer
            </base-button>

            <base-button class="flex-1" @click="onLeave">Done</base-button>
          </div>
        </div>
      </div>

      <div
        v-else
        class="mx-auto flex h-full min-h-full w-full flex-col bg-white px-10 py-[30px] sm:w-sm sm:rounded-t-3xl"
      >
        <base-loader class="mx-auto" />
      </div>
    </div>
  </div>
</template>
